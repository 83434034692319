body, html {
  overflow-x: hidden;
}

App {
  overflow-x: hidden;
}

.company-logo {
  background: #0F4C75;
  color: #fff;
  padding: 1rem 2rem;
  border-radius: 0px 0px 40px 0px;
}

.company-logo > img {
  width: 6rem;
}

.sub-image {
  margin-top: -8rem;
}
.sub-image-2 {
  margin-top: -13rem;
  margin-left: -104rem;
  width: 100% !important;
  height: 100% !important;
  position: absolute;
}
.sub-image-3 {
  rotate: 180 !important;
  width: 6rem;
  height: 5rem;
}

.sub-image-5{
  position: absolute;
  margin-left: -15rem;
  margin-top: -15rem;
  overflow-x: hidden !important;
  /* width: 90%; */
  /* height: 90%; */
  
}

.section-1 .company-logo {
  margin-left: 3rem;
}
.section-1 {
  background-color: white;
  background: url(https://res.cloudinary.com/emacon-production/image/upload/v1637569258/Bizmkononi/Group_105_lfypbm.svg);
  background-position: right;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position-y: -10rem;
  background-position-x: 43rem;
  height:80vh;
}

.section-1 .navigation {
  display: flex;
  justify-content: space-between;
}

.section-1 .hero{
  display: flex;
  align-items: center;
  padding: 8rem 0rem;
}

.section-1 .socials > a {
  color:#0F4C75;
  font-size: 25px;
  padding: 1rem 3rem;
}

.section-1 .socials > a:hover {
  color:royalblue;
  font-size: 25px;
  padding: 1rem 3rem;
}

.section-1 .hero .details {
  padding-left: 2rem;
}

.section-1 .hero .details > h1 {
  color: #0F4C75;
}

.section-1 .hero .details > p {
  color: #1B262C;
}

.section-1L .company-logo {
  margin-left: 3rem;
}
.section-1L {
  background-color: #0F4C75;
  /* height:80vh; */
}

.section-1L .navigation {
  display: flex;
  justify-content: space-between;
}

.section-2 {
  text-align: center;
  padding: 2rem 0rem;
  background: url(https://res.cloudinary.com/emacon-production/image/upload/v1637772604/Bizmkononi/wave_4_g4ka9o.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-y: 19rem;
}

.section-2 .uniquness-cards {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-2L {
  text-align: center;
  padding: 2rem 0rem;
  background: url(https://res.cloudinary.com/emacon-production/image/upload/v1637772604/Bizmkononi/wave_4_g4ka9o.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-y: 115rem;
}

.section-2L .uniquness-cards {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-2L .uniquness-cards .uniquness-card{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.section-3 {
  padding: 6rem 19rem 0rem 0rem;
}

.section-3 .feature-basket {
  display: flex;
  justify-content: end;
  align-items: center;
}

.section-3 .feature-basket > h2 {
  margin-left: -2rem;
}

.section-3 .feature-basket > p {
  margin-right: -12rem !important;
}

.section-3 .feature-basket-btn {
  display: flex;
  justify-content: end;
  margin-right: 10rem;
}

.section-4 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 5rem;
  /* overflow-x: hidden; */
}

.section-4 .features-cards {
  display: flex;
  justify-content: center;
}

.section-4 .wrapper {
  overflow-x: hidden !important;
}

.section-5 {
  margin-top: 1.5rem;
  padding: 4rem 15rem;
  background: #bbe1fa;
}

.section-5 > h2{
  text-align: center;
}

.section-5 .time-selector {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-5 .time-selector-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem;
}

.section-5 .price-list {
  display: flex;
  justify-content: center;
}

.section-6 {
  padding: 5rem 6rem 0rem 0rem;
}

.section-6 .feature {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-6 .feature-basket {
  display: flex;
  align-items: center;
}

.section-6 .feature-basket > h2 {
  margin-left: -2rem;
}

.section-6 .feature-basket > p {
  margin-left: 4rem !important;
}

.section-6 .feature-basket-btn {
  display: flex;
  margin-left: 4rem;
}

.section-6 .feature-img > img {
  margin-left: 3rem;
  height: 90%;
  width: 90%;
}

.section-6 .feature-basket-img{
  display: flex;
  justify-content: end;
}

.section-7 {
  padding: 3.2rem 6rem 0rem 10rem;
  background: url(https://res.cloudinary.com/emacon-production/image/upload/v1637772604/Bizmkononi/wave_3_wqfwlo.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-y: 13.5rem;
  margin-top: 2rem;
}

.section-7 .feature {
  display: flex;
  align-items: center;
}

.section-7 .feature-basket {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.section-7 .feature-basket > h2 {
  margin-left: -2rem;
}

.section-7 .feature-basket > p {
  margin-left: 4rem !important;
}

.section-7 .feature-basket-btn {
  display: flex;
  margin-left: 4rem;
}

.section-7 .feature-img > img {
  margin-left: 3rem;
  height: 80%;
  width: 80%;
}

.section-7 .feature-basket-img{
  display: flex;
  justify-content: center;
}

.section-8 {
  background: #0F4C75;
  color: #fff;
  padding: 5rem 5rem 2rem 5rem;
}

.section-8 .footer {
  display: flex;
}
.section-8 .footer .links-footer a {
  display: flex;
}
.section-8 .footer .links-footer a {
  color: #fff;
    text-decoration: none;
    padding: 10px;
}
.section-8 .footer .links-footer a:hover {
  color: #1B262C;
}

.section-8 .footer > h6 {
  margin-right: 1.5rem;
}

.section-8 .socials-footer .socials{
  display: flex;
  justify-content: end;
}

.section-8 .socials-footer .footer .socials > a{
  color: #fff;
  font-size: 20px;
}


.section-8 .copyright{
  text-align: center;
  margin-top: 5rem;
}

.section-9 {
  padding: 4rem 4rem;
}

@media screen and (max-width: 320px) {
  .section-1 .hero{
    padding: 2rem 1rem;
  }
  .section-1 .socials > a {
    font-size: 22px;
    padding: 0rem 0rem !important;
  }
  .section-1 .hero .details {
    padding-left: 4rem;
    margin-top: -6rem !important;
    z-index: 1;
  }

  .section-1 .hero .details > h1 {
    color: #0F4C75;
  }

  .section-1 .hero .details > p {
    color: #1B262C;
  }
  .section-2 {
    height: 100rem !important;
    padding: 0rem 1.5rem;
    background-position-y: 87rem;
    margin-top: -10rem;
  }
  .section-3 {
    padding: 0rem 1.5rem 0rem 1.5rem;
    margin-top: -4rem;
  }
  .section-3 .feature-basket {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .section-3 .feature-basket > h2 {
    margin-left: -3rem;
  }

  .section-3 .feature-basket > p {
    margin-right: 0rem !important;
  }

  .section-3 .feature-basket-btn {
    display: flex;
    justify-content: end;
    margin-right: 1rem;
  }
  .section-4 .wrapper > img {
    display: none;
  }
  .section-5 {
    padding: 3rem 1rem;
  }

  .section-5 .time-selector > h6 {
    display: none;
  }

  .section-5 .time-selector-btn {
    margin-top: 1rem;
  }

  .section-7 {
    padding: 0rem 0rem 0rem 0rem;
    background-position-y: 35rem;
    margin-top: -7rem;
  }

  .section-7 .feature-basket {
    margin-top: 1rem;
  }

  .section-7 .feature-basket > p {
    margin-left: 0rem !important;
    padding: 0rem 2rem;
  }

  .section-7 .feature-basket-btn {
    margin-left: 2rem;
  }

  .section-7 .feature-img > img {
    margin-top: -6rem;
    height: 90%;
    width: 90%;
  }
  .section-6 {
    padding: 0rem 2rem 0rem 2rem;
  }

  .section-6 .feature-basket > h2 {
    margin-left: -6rem;
  }

  .section-6 .feature-basket > p {
    margin-left: 0rem !important;
  }

  .section-6 .feature-basket-btn {
    margin-left: 0rem;
  }

  .section-6 .feature-img > img {
    margin-top: 1rem;
    margin-left: 0rem;
    height: 90%;
    width: 90%;
  }

  .section-6 .feature-basket-img{
    display: flex;
    justify-content: end;
  }
  .section-4 {
    padding: 0rem 0rem;
    /* overflow-x: hidden; */
  }

  .section-8 {
    padding: 4rem 0rem 0rem 2rem;
  }
  .section-2L {
    background-position-y: 312rem;
  }
}

@media screen and (max-width: 360px) {
  .section-1 .hero{
    padding: 2rem 1rem;
  }
  .section-1 .socials > a {
    font-size: 22px;
    padding: 0rem 0rem !important;
  }
  .section-1 .hero .details {
    padding-left: 4rem;
    margin-top: -6rem !important;
    z-index: 1;
  }

  .section-1 .hero .details > h1 {
    color: #0F4C75;
  }

  .section-1 .hero .details > p {
    color: #1B262C;
  }
  .section-2 {
    height: 100rem !important;
    padding: 0rem 1.5rem;
    background-position-y: 87rem;
    margin-top: -10rem;
  }
  .section-3 {
    padding: 0rem 1.5rem 0rem 1.5rem;
    margin-top: -4rem;
  }
  .section-3 .feature-basket {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .section-3 .feature-basket > h2 {
    margin-left: -3rem;
  }

  .section-3 .feature-basket > p {
    margin-right: 0rem !important;
  }

  .section-3 .feature-basket-btn {
    display: flex;
    justify-content: end;
    margin-right: 1rem;
  }
  .section-4 .wrapper > img {
    display: none;
  }
  .section-5 {
    padding: 3rem 1rem;
  }

  .section-5 .time-selector > h6 {
    display: none;
  }

  .section-5 .time-selector-btn {
    margin-top: 1rem;
  }

  .section-7 {
    padding: 0rem 0rem 0rem 0rem;
    background-position-y: 35rem;
    margin-top: -7rem;
  }

  .section-7 .feature-basket {
    margin-top: 1rem;
  }

  .section-7 .feature-basket > p {
    margin-left: 0rem !important;
    padding: 0rem 2rem;
  }

  .section-7 .feature-basket-btn {
    margin-left: 2rem;
  }

  .section-7 .feature-img > img {
    margin-top: -6rem;
    height: 90%;
    width: 90%;
  }
  .section-6 {
    padding: 0rem 2rem 0rem 2rem;
  }

  .section-6 .feature-basket > h2 {
    margin-left: -6rem;
  }

  .section-6 .feature-basket > p {
    margin-left: 0rem !important;
  }

  .section-6 .feature-basket-btn {
    margin-left: 0rem;
  }

  .section-6 .feature-img > img {
    margin-top: 1rem;
    margin-left: 0rem;
    height: 90%;
    width: 90%;
  }

  .section-6 .feature-basket-img{
    display: flex;
    justify-content: end;
  }
  .section-4 {
    padding: 0rem 0rem;
    /* overflow-x: hidden; */
  }

  .section-8 {
    padding: 4rem 0rem 0rem 2rem;
  }
  .section-2L {
    background-position-y: 312rem;
  }
}

@media screen and (max-width: 375px) {
  .section-1 .hero{
    padding: 2rem 1rem;
  }
  .section-1 .socials > a {
    font-size: 22px;
    padding: 0rem 0rem !important;
  }
  .section-1 .hero .details {
    padding-left: 4rem;
    margin-top: -6rem !important;
    z-index: 1;
  }

  .section-1 .hero .details > h1 {
    color: #0F4C75;
  }

  .section-1 .hero .details > p {
    color: #1B262C;
  }
  .section-2 {
    height: 100rem !important;
    padding: 0rem 1.5rem;
    background-position-y: 87rem;
    margin-top: -10rem;
  }
  .section-3 {
    padding: 0rem 1.5rem 0rem 1.5rem;
    margin-top: -4rem;
  }
  .section-3 .feature-basket {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .section-3 .feature-basket > h2 {
    margin-left: -3rem;
  }

  .section-3 .feature-basket > p {
    margin-right: 0rem !important;
  }

  .section-3 .feature-basket-btn {
    display: flex;
    justify-content: end;
    margin-right: 1rem;
  }
  .section-4 .wrapper > img {
    display: none;
  }
  .section-5 {
    padding: 3rem 1rem;
  }

  .section-5 .time-selector > h6 {
    display: none;
  }

  .section-5 .time-selector-btn {
    margin-top: 1rem;
  }

  .section-7 {
    padding: 0rem 0rem 0rem 0rem;
    background-position-y: 35rem;
    margin-top: -7rem;
  }

  .section-7 .feature-basket {
    margin-top: 1rem;
  }

  .section-7 .feature-basket > p {
    margin-left: 0rem !important;
    padding: 0rem 2rem;
  }

  .section-7 .feature-basket-btn {
    margin-left: 2rem;
  }

  .section-7 .feature-img > img {
    margin-top: -6rem;
    height: 90%;
    width: 90%;
  }
  .section-6 {
    padding: 0rem 2rem 0rem 2rem;
  }

  .section-6 .feature-basket > h2 {
    margin-left: -6rem;
  }

  .section-6 .feature-basket > p {
    margin-left: 0rem !important;
  }

  .section-6 .feature-basket-btn {
    margin-left: 0rem;
  }

  .section-6 .feature-img > img {
    margin-top: 1rem;
    margin-left: 0rem;
    height: 90%;
    width: 90%;
  }

  .section-6 .feature-basket-img{
    display: flex;
    justify-content: end;
  }
  .section-4 {
    padding: 0rem 0rem;
    /* overflow-x: hidden; */
  }

  .section-8 {
    padding: 4rem 0rem 0rem 2rem;
  }

  .section-2L {
    background-position-y: 312rem;
  }
}

@media screen and (max-width: 414px) {
  .section-1 .hero{
    padding: 2rem 1rem;
  }
  .section-1 .socials > a {
    font-size: 22px;
    padding: 0rem 0rem !important;
  }
  .section-1 .hero .details {
    padding-left: 4rem;
    margin-top: -6rem !important;
    z-index: 1;
  }

  .section-1 .hero .details > h1 {
    color: #0F4C75;
  }

  .section-1 .hero .details > p {
    color: #1B262C;
  }
  .section-2 {
    height: 100rem !important;
    padding: 0rem 1.5rem;
    background-position-y: 87rem;
    margin-top: -10rem;
  }
  .section-3 {
    padding: 0rem 1.5rem 0rem 1.5rem;
    margin-top: -4rem;
  }
  .section-3 .feature-basket {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .section-3 .feature-basket > h2 {
    margin-left: -3rem;
  }

  .section-3 .feature-basket > p {
    margin-right: 0rem !important;
  }

  .section-3 .feature-basket-btn {
    display: flex;
    justify-content: end;
    margin-right: 1rem;
  }
  .section-4 .wrapper > img {
    display: none;
  }
  .section-5 {
    padding: 3rem 1rem;
  }

  .section-5 .time-selector > h6 {
    display: none;
  }

  .section-5 .time-selector-btn {
    margin-top: 1rem;
  }

  .section-7 {
    padding: 0rem 0rem 0rem 0rem;
    background-position-y: 35rem;
    margin-top: -7rem;
  }

  .section-7 .feature-basket {
    margin-top: 1rem;
  }

  .section-7 .feature-basket > p {
    margin-left: 0rem !important;
    padding: 0rem 2rem;
  }

  .section-7 .feature-basket-btn {
    margin-left: 2rem;
  }

  .section-7 .feature-img > img {
    margin-top: -6rem;
    height: 90%;
    width: 90%;
  }
  .section-6 {
    padding: 0rem 2rem 0rem 2rem;
  }

  .section-6 .feature-basket > h2 {
    margin-left: -6rem;
  }

  .section-6 .feature-basket > p {
    margin-left: 0rem !important;
  }

  .section-6 .feature-basket-btn {
    margin-left: 0rem;
  }

  .section-6 .feature-img > img {
    margin-top: 1rem;
    margin-left: 0rem;
    height: 90%;
    width: 90%;
  }

  .section-6 .feature-basket-img{
    display: flex;
    justify-content: end;
  }
  .section-4 {
    padding: 0rem 0rem;
    margin-top: -6rem;
  }

  .section-8 {
    padding: 4rem 0rem 0rem 2rem;
  }

  .section-2L {
    background-position-y: 312rem;
  }
}
