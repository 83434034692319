.navWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s;
}

.nav {
    height: 70px;
    padding: 0.5rem 2rem;
    /* position: fixed */
    background: transparent;
    transition: all .4s;
}

.navMenu {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navMenuLink {
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0rem 2rem;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
    font-size: 15px;
    margin-top: 1rem;
}

.navMenuLink:hover {
    color: #1d8ad3;
}
