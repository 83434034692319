.card {
    width: 23rem;
    height: 30rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: none;
    
    padding: 2rem 0rem;
    margin-top: 1rem;
}

.priceList {
    width: 22rem;
    height: 43rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: none;
    
    padding: 2rem 0rem;
    margin-top: 1rem;
}

.card > h3 {
    text-align: center;
    color: #fff;
}

.priceList > h3 {
    text-align: center;
    color: #fff;
}

.white {
    background: #fff;
}

.blue {
    background: #0F4C75;
}

.topCorner {
    border-radius: 0px 40px 0px 0px;
}

.normalCorner {
    border-radius: 15px;
}

.zero {
    margin-top: -27rem;
}

.one {
    margin-top: -20rem;
}

.two {
    margin-top: -9rem;
}

.three {
    margin-top: 2rem;
    /* margin-left: -13rem; */
}

.four {
    margin-top: -5rem;
    /* margin-left: -13rem; */
}

.card-img {
    display: flex;
    justify-content: center;
}

.card-img > img {
    width: 35%;
}

.card-title {
    margin-top: 1rem;
    padding: 0rem 2rem;

}

.card-description {
    padding: 0rem 2rem;
}

.priceList .package-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 4rem;
    color: #fff;
}

.priceList .package-details > p {
    margin-top: 1rem;
}

.priceList .package-details > i {
    font-size: 25px;
}

.priceList .trial-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.member .member_img {
    display: flex;
    justify-content: center;
}

.member .member_img > img {
    height: 15rem;
    width: 15rem;
    border-radius: 50%;
    object-fit: cover;
}

.member .member_details {
    text-align: center;
    margin-top: 2rem;
}

.member .member_details > h4 {
    font-weight: bold;
}

.member .member_details > p {
    font-size: 20px;
    color: #0F4C75;
}

@media screen and (max-width: 320px) {
    .card {
        width: 22rem;
        height: 28rem;
        
        padding: 2rem 0rem;
        margin-top: 1rem;
    }
    .zero {
        margin-top: 0rem;
        margin-bottom: 1rem;
    }

    .one {
        margin-top: 0rem;
        margin-bottom: 1rem;
    }

    .two {
        margin-top: 0rem;
        margin-bottom: 1rem;
    }

    .three {
        margin-top: 0rem;
        margin-bottom: 1rem;
    }

    .four {
        margin-top: 0rem;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 360px) {
    .card {
        width: 22rem;
        height: 28rem;
        
        padding: 2rem 0rem;
        margin-top: 1rem;
    }
    .zero {
        margin-top: 0rem;
        margin-bottom: 1rem;
    }

    .one {
        margin-top: 0rem;
        margin-bottom: 1rem;
    }

    .two {
        margin-top: 0rem;
        margin-bottom: 1rem;
    }

    .three {
        margin-top: 0rem;
        margin-bottom: 1rem;
    }

    .four {
        margin-top: 0rem;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 375px) {
    .card {
        width: 22rem;
        height: 28rem;
        
        padding: 2rem 0rem;
        margin-top: 1rem;
    }
    .zero {
        margin-top: 0rem;
        margin-bottom: 1rem;
    }

    .one {
        margin-top: 0rem;
        margin-bottom: 1rem;
    }

    .two {
        margin-top: 0rem;
        margin-bottom: 1rem;
    }

    .three {
        margin-top: 0rem;
        margin-bottom: 1rem;
    }

    .four {
        margin-top: 0rem;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 414px) {
    .card {
        width: 22rem;
        height: 28rem;
        
        padding: 2rem 0rem;
        margin-top: 1rem;
    }
    .zero {
        margin-top: 0rem;
        margin-bottom: 1rem;
    }

    .one {
        margin-top: 0rem;
        margin-bottom: 1rem;
    }

    .two {
        margin-top: 0rem;
        margin-bottom: 1rem;
    }

    .three {
        margin-top: 0rem;
        margin-bottom: 1rem;
    }

    .four {
        margin-top: 0rem;
        margin-bottom: 1rem;
    }
}
