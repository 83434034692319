.transparent {
  padding: 0rem 1rem;
}

.transparent > button {
  padding: 0.5rem 1rem;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  transition: all 0.3s;
}

.transparent > button:hover {
  background: #0f4c75 !important;
  border: 1px solid #0f4c75;
  border-radius: 5px;
  color: #fff;
}

.main {
  /* margin-top: 1.5rem; */
  z-index: 99999 !important;
}

.main > button {
  padding: 0.5rem 1rem;
  background: #0f4c75;
  border: none;
  border-radius: 5px;
  color: #fff;
  transition: all 0.3s;
}

.main > button:hover {
  background: royalblue;
  border: none;
  border-radius: 5px;
  color: #fff;
}

.top-one {
  margin-top: 1rem;
}
